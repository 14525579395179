import React, { ReactElement } from "react";
import Seo from "../../../components/Seo/seo";
import {
  CaseStudy,
  HeroSection,
  ColumnsSection,
  Banner,
  CaseStudyDetails,
} from "../../../components/CaseStudy";
import KardiologDetails from "../../../assets/imgs/case-study/kardiolog/Details.svg";
import ActivityChartsIcon from "../../../assets/imgs/case-study/kardiolog/icons/ActivityChartsIcon.svg";
import DataDiaryIcon from "../../../assets/imgs/case-study/kardiolog/icons/DataDiaryIcon.svg";
import EducationIcon from "../../../assets/imgs/case-study/kardiolog/icons/EducationIcon.svg";
import PulseChartsIcon from "../../../assets/imgs/case-study/kardiolog/icons/PulseChartsIcon.svg";
import RemindersIcon from "../../../assets/imgs/case-study/kardiolog/icons/RemindersIcon.svg";
import { graphql } from "gatsby";

export default function captio({ data }): ReactElement {
  const {
    heroImage,
    heroLogo,
    howItWorks,
    challange,
    solution,
    datadiary,
    reminders,
    education,
    pulsecharts,
    activitycharts,
  } = data;

  return (
    <div className="container">
      <Seo title={"KardioLog"} />
      <CaseStudy
        title="Health app"
        body={
          <>
            <HeroSection
              heroTitle="Smart health monitoring"
              heroDescription={[
                "Your smart assistant in heart failure monitoring",
                "Delivered by cardiologs",
              ]}
              heroDescriptionStyle={{ paddingTop: "350px" }}
              heroImage={heroImage.childImageSharp.fixed}
              heroLogo={heroLogo.childImageSharp.fixed}
              heroLogoClass="kardiologHeroLogo"
            />
            <ColumnsSection
              customClass="kardiolog"
              columns={[
                {
                  title: "How it works",
                  description: [
                    "It makes monitoring easy and encourages patients to care more about their health. Plus, the large font makes the app accessible to all ages. All the app's features provide support to a patient in a course of therapy. Each of them follows the latest European guidelines related to the treatment of heart failure.",
                  ],
                  image: howItWorks.childImageSharp.fixed,
                },
                {
                  title: "What was the challenge?",
                  description: [
                    "HF is one of the most significant public health issues contributing to higher worldwide mortality.  In deve-loped countries, heart failure affects 1-2% of the adult population and more than 10% of people over 70.",
                  ],
                  image: challange.childImageSharp.fixed,
                },
                {
                  title: "Solution",
                  description: [
                    "Patients frequently lack relevant disease knowledge and corresponding self-care skills. Regular monitoring combined with a self-care routine is a potent way to improve health and respond to changes in your condition.",
                    "",
                    "It makes monitoring easy and encourages patients to care more about their health. Plus, the large font makes the app accessible to all ages.",
                  ],
                  image: solution.childImageSharp.fixed,
                },
              ]}
            />
            <ColumnsSection
              customClass="white-bg reverse flex"
              columns={[
                {
                  icon: <DataDiaryIcon />,
                  subtitle: "Personal health data diary",
                  description: [
                    "KardioLog encourages patients to measure blood pressure, pulse, weight, the grade of oedema and shortness of breath. They can enter results quickly and access them in one place, anytime.",
                  ],
                  image: datadiary.childImageSharp.fixed,
                },
                {
                  icon: <RemindersIcon />,
                  subtitle: "Medication reminders",
                  description: [
                    "Helped by timely reminders, patients are more likely to take medicines as planned. Following the medication guidance helps make their therapy more effective.",
                  ],
                  image: reminders.childImageSharp.fixed,
                },
                {
                  icon: <EducationIcon />,
                  subtitle: "Patient education",
                  description: [
                    "Articles explain all aspects of the disease and the patient's therapy. Cardiologists work with writers to creating articles that can be easily understood.",
                  ],
                  image: education.childImageSharp.fixed,
                },
                {
                  icon: <PulseChartsIcon />,
                  subtitle: "Blood pressure, pulse and weight charts",
                  description: [
                    "Collecting and monitoring patient data helps in the optimisation of pharmacotherapy. Alongside checks for swelling and shortness of breath, weight charts are helpful in deter-mining the risk of water accumulation in the body. Charts of dyspnea and oedema help in assessing the severity of symptoms over time.",
                  ],
                  image: pulsecharts.childImageSharp.fixed,
                },
                {
                  icon: <ActivityChartsIcon />,
                  subtitle: "Physical activity charts",
                  description: [
                    "Statistics help determine whether the patient is building healthy habits, or if decreasing activity may relate to a worsening condition.",
                  ],
                  image: activitycharts.childImageSharp.fixed,
                },
              ]}
            />
            <Banner
              title="What makes us different?"
              subtitle="Based on the latest guidelines from the European Society of Cardiology. Educational materials edited by practitioners in the field of heart diseases. Health reports designed for easy consultations with practitioners. Easy to use, beautiful and intuitive design for users at any age."
            />
            <CaseStudyDetails
              title="kardiolog in details"
              image={<KardiologDetails />}
            />
          </>
        }
      />
    </div>
  );
}

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "imgs/case-study/kardiolog/KardiologHero.png" }
    ) {
      childImageSharp {
        fixed(width: 1175, height: 783, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heroLogo: file(
      relativePath: { eq: "imgs/case-study/kardiolog/KardiologLogo.png" }
    ) {
      childImageSharp {
        fixed(width: 96, height: 105, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    howItWorks: file(
      relativePath: { eq: "imgs/case-study/kardiolog/howitworks.png" }
    ) {
      childImageSharp {
        fixed(width: 524, height: 457, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    challange: file(
      relativePath: { eq: "imgs/case-study/kardiolog/challange.png" }
    ) {
      childImageSharp {
        fixed(width: 527, height: 525, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    solution: file(
      relativePath: { eq: "imgs/case-study/kardiolog/solution.png" }
    ) {
      childImageSharp {
        fixed(width: 516, height: 390, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    datadiary: file(
      relativePath: { eq: "imgs/case-study/kardiolog/datadiary.png" }
    ) {
      childImageSharp {
        fixed(width: 411, height: 398, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reminders: file(
      relativePath: { eq: "imgs/case-study/kardiolog/reminders.png" }
    ) {
      childImageSharp {
        fixed(width: 355, height: 403, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    education: file(
      relativePath: { eq: "imgs/case-study/kardiolog/education.png" }
    ) {
      childImageSharp {
        fixed(width: 411, height: 400, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pulsecharts: file(
      relativePath: { eq: "imgs/case-study/kardiolog/pulsecharts.png" }
    ) {
      childImageSharp {
        fixed(width: 355, height: 408, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    activitycharts: file(
      relativePath: { eq: "imgs/case-study/kardiolog/activitycharts.png" }
    ) {
      childImageSharp {
        fixed(width: 411, height: 400, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
